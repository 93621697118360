//import { IAction } from "../../types/actionsInterface";
import { IReservation } from "../../types/reservationInterface";
import actionTypes from "../actions/actionTypes";

const reservationReducer = (
  reservations: Array<IReservation> = [],
  action: any
) => {
  let newReservation;
  switch (action.type) {
    case actionTypes.createReservation:
      newReservation = [...reservations, action.reservation];
      break;
    case actionTypes.loadAllReservations:
      newReservation = [...action.reservations];
      break;
    case actionTypes.deleteReservation:
      newReservation = reservations.filter(
        (reservation) =>
          reservation["idGenerated"] !== action.reservation.idGenerated
      );
      break;
    default:
      newReservation = reservations;
  }
  return newReservation;
};

export default reservationReducer;
