import "./GuestsCounter.scss";
import InfoIcon from "@mui/icons-material/Info";
//import { IGuestsCounterProps } from "../../../types/propsInterfaces";
//import { useState } from "react";

const GuestsCounter = ({
  totalAdults,
  totalChildren,
  totalResidents,
  setTotalAdults,
  setTotalChildren,
  setTotalResidents,
  setIsDisable,
  isDisable,
  maxPeopleHour,
  valueAdults,
  setValueAdults,
  valueChildren,
  setValueChildren,
  valueResidents,
  setValueResidents,
}: any) => {
  const maxPeople = 10;

  const onClickPlus = (event: any) => {
    event.preventDefault();
    setTotalAdults(valueAdults);
    setTotalChildren(valueChildren);
    setTotalResidents(valueResidents);

    if (
      event.target.id === "adults" &&
      valueAdults < maxPeople - totalChildren - totalResidents - maxPeopleHour
    ) {
      setValueAdults(valueAdults + 1);
    }
    if (
      event.target.id === "children" &&
      valueChildren < maxPeople - totalAdults - totalResidents - maxPeopleHour
    ) {
      setValueChildren(valueChildren + 1);
    }
    if (
      event.target.id === "residents" &&
      valueResidents < maxPeople - totalAdults - totalChildren - maxPeopleHour
    ) {
      setValueResidents(valueResidents + 1);
    }
  };

  const onClickMinus = (event: any) => {
    event.preventDefault();
    setTotalAdults(valueAdults);
    setTotalChildren(valueChildren);
    setTotalResidents(valueResidents);
    if (event.target.id === "adults" && valueAdults > 0) {
      setValueAdults(valueAdults - 1);
    }
    if (event.target.id === "children" && valueChildren > 0) {
      setValueChildren(valueChildren - 1);
    }
    if (event.target.id === "residents" && valueResidents > 0) {
      setValueResidents(valueResidents - 1);
    }
  };
  return (
    <div className="guests">
      <div className="guests__input-container">
        <div className="guests__info-container">
          <div className="guests__text">
            <h3 className="guests__title">
              {valueAdults === 1 ? "Adult" : "Adults"}
            </h3>
            <p className="guests__price">20$ | 40,000tsh pp</p>
          </div>
          <div className="guests__buttons">
            <div
              className="guests__container-button"
              onClick={(e) => {
                onClickMinus(e);
              }}
              id="adults"
            >
              <button className="guests__button" id="adults">
                <img
                  src="media/menos.png"
                  alt=""
                  height={10}
                  width={10}
                  id="adults"
                />
              </button>
            </div>

            <input
              type="text"
              id="adults"
              value={valueAdults}
              min={0}
              max={maxPeople - totalChildren - totalResidents}
              disabled
            />
            <div
              className="guests__container-button"
              onClick={(e) => {
                onClickPlus(e);
              }}
              id="adults"
            >
              <button className="guests__button" id="adults">
                <img
                  src="media/mas.png"
                  alt=""
                  height={10}
                  width={10}
                  id="adults"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="guests__input-container">
        <div className="guests__info-container">
          <div className="guests__text">
            <h3 className="guests__title">
              {valueChildren === 1 ? "Child " : "Children "}
              <span className="guests__title--info">(from 5 to 13 years)</span>
            </h3>
            <p className="guests__price">10$ | 20,000tsh pp</p>
          </div>
          <div className="guests__buttons">
            <div
              className="guests__container-button"
              onClick={(e) => onClickMinus(e)}
              id="children"
            >
              <button className="guests__button" id="children">
                <img
                  src="media/menos.png"
                  alt=""
                  height={10}
                  width={10}
                  id="children"
                />
              </button>
            </div>
            <input
              type="text"
              id="children"
              value={valueChildren}
              min={0}
              max={maxPeople - totalAdults - totalResidents}
              disabled
            />
            <div
              className="guests__container-button"
              onClick={(e) => {
                onClickPlus(e);
              }}
              id="children"
            >
              <button className="guests__button" id="children">
                <img
                  src="media/mas.png"
                  alt=""
                  height={10}
                  width={10}
                  id="children"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="guests__input-container">
        <div className="guests__info-container">
          <div className="guests__text">
            <h3 className="guests__title">
              {valueResidents === 1 ? "Resident " : "Residents "}
              <span className="guests__title--info">
                (national ID required)
              </span>
            </h3>
            <p className="guests__price">15$ | 30,000tsh pp</p>
          </div>
          <div className="guests__buttons">
            <div
              className="guests__container-button"
              onClick={(e) => onClickMinus(e)}
              id="residents"
            >
              <button className="guests__button" id="residents">
                <img
                  src="media/menos.png"
                  alt=""
                  height={10}
                  width={10}
                  id="residents"
                />
              </button>
            </div>
            <input
              type="text"
              id="residents"
              value={valueResidents}
              min={0}
              max={maxPeople - totalChildren - totalAdults}
              disabled
            />
            <div
              className="guests__container-button"
              onClick={(e) => {
                onClickPlus(e);
              }}
              id="residents"
            >
              <button className="guests__button" id="residents">
                <img
                  src="media/mas.png"
                  alt=""
                  id="residents"
                  height={10}
                  width={10}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <p className="guests__info">
        <InfoIcon fontSize="inherit" />
        Group bookings of 5pax or more will require advance payment
      </p>
    </div>
  );
};

export default GuestsCounter;
