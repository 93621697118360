//import Header from "../../components/Header/Header";
import "./Reservations.scss";
import Calendar from "../../components/Reservation/Calendar/Calendar";
import { useState } from "react";
import { IReservation } from "../../types/reservationInterface";
import GuestInfo from "../../components/Reservation/GuestInfo/GuestInfo";
// import Confirmation from "../../components/Reservation/Confirmation/Confirmation";
import Header from "../../components/Reservation/Header/Header";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
// import DeleteCheckReservation from "../../components/Reservation/DeleteCheckReservation/DeleteCheckReservation";
import useReservations from "../../hooks/useReservations";

const Reservations = ({
  showReservation,
  setShowReservation,
  setStepper,
  stepper,
}: any): JSX.Element => {
  const initialReservationValue: IReservation = {
    name: "",
    surname: "",
    phone: null,
    email: "",
    adults: 0,
    children: 0,
    residents: 0,
    date: new Date(),
    idGenerated: "",
  };
  const { clearReservation } = useReservations();
  const [guestReservation, setGuestReservation] = useState(
    initialReservationValue
  );
  const initialValue = 0;
  const [valueAdults, setValueAdults] = useState(initialValue);
  const [valueChildren, setValueChildren] = useState(initialValue);
  const [valueResidents, setValueResidents] = useState(initialValue);
  const [dateFlag, setDateFlag] = useState(false);
  const [userInfoFlag, setUserInfoFlag] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);

  const modalDialog = (flag: any) => {
    return (
      <Dialog open={flag} className="dialog">
        <DialogTitle>Exit process</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {stepper !== 4
              ? "When exiting the process all changes will be lost"
              : ""}
          </DialogContentText>
          <DialogContentText>Do you want to continue?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justifyContent="end">
            <Grid item>
              <Button
                color="secondary"
                size="small"
                onClick={() => setOpenWarningDialog(false)}
                sx={{ color: "#4f6029d4" }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{ background: "#4f6029d4" }}
                onClick={() => {
                  setShowReservation(false);
                  setOpenWarningDialog(false);

                  document.body.classList.remove("body--scroll-disable");
                  setTimeout(() => {
                    setStepper(1);
                    clearReservation();
                    setGuestReservation(initialReservationValue);
                  }, 500);
                  setValueResidents(0);
                  setValueChildren(0);
                  setValueAdults(0);
                }}
              >
                Exit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  const stepperFunction = () => {
    switch (stepper) {
      case 1:
        return (
          <>
            <Header
              setShowReservation={setShowReservation}
              headerString={"Select Date / Select Time"}
              stepper={stepper}
              setStepper={setStepper}
              setOpenWarningDialog={setOpenWarningDialog}
            />
            {modalDialog(openWarningDialog)}
            <Calendar
              guestReservation={guestReservation}
              setGuestReservation={setGuestReservation}
              dateFlag={dateFlag}
              setDateFlag={setDateFlag}
              showReservation={showReservation}
              isDisable={isDisable}
              setIsDisable={setIsDisable}
              setShowReservation={setShowReservation}
              setStepper={setStepper}
              stepper={stepper}
              setValueAdults={setValueAdults}
              setValueResidents={setValueResidents}
              setValueChildren={setValueChildren}
              valueAdults={valueAdults}
              valueChildren={valueChildren}
              valueResidents={valueResidents}
            />
          </>
        );
      case 2:
        return (
          <>
            <Header
              setShowReservation={setShowReservation}
              headerString={"Contact information"}
              stepper={stepper}
              setStepper={setStepper}
              setOpenWarningDialog={setOpenWarningDialog}
            />
            {modalDialog(openWarningDialog)}
            <GuestInfo
              guestReservation={guestReservation}
              setGuestReservation={setGuestReservation}
              showReservation={showReservation}
              dateFlag={dateFlag}
              setDateFlag={setDateFlag}
              userInfoFlag={userInfoFlag}
              setUserInfoFlag={setUserInfoFlag}
              setStepper={setStepper}
              stepper={stepper}
            />
          </>
        );
      // case 3:
      //   return (
      //     <>
      //       <Header
      //         setShowReservation={setShowReservation}
      //         headerString={"Send request"}
      //         stepper={stepper}
      //         setStepper={setStepper}
      //         setOpenWarningDialog={setOpenWarningDialog}
      //       />
      //       {modalDialog(openWarningDialog)}
      //       <Confirmation
      //         guestReservation={guestReservation}
      //         setGuestReservation={setGuestReservation}
      //         userInfoFlag={userInfoFlag}
      //         setUserInfoFlag={setUserInfoFlag}
      //         showReservation={showReservation}
      //         setShowReservation={setShowReservation}
      //         setStepper={setStepper}
      //         initialReservationValue={initialReservationValue}
      //       />
      //     </>
      //   );
      // case 4:
      //   return (
      //     <>
      //       <Header
      //         setShowReservation={setShowReservation}
      //         headerString={"Check reservation"}
      //         stepper={stepper}
      //         setStepper={setStepper}
      //         setOpenWarningDialog={setOpenWarningDialog}
      //       />
      //       {modalDialog(openWarningDialog)}
      //       <DeleteCheckReservation
      //         showReservation={showReservation}
      //         setShowReservation={setShowReservation}
      //         stepper={stepper}
      //         setStepper={setStepper}
      //         clearReservation={clearReservation}
      //       />
      //     </>
      //   );
    }
  };
  return (
    <div className={showReservation ? "modal-reservations" : " "}>
      <section
        className={
          !showReservation
            ? "reservations"
            : "reservations reservations--active"
        }
      >
        {stepperFunction()}
      </section>
    </div>
  );
};

export default Reservations;
