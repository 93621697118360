import { Snackbar, Alert, AlertTitle } from "@mui/material";
import { messageActions } from "../../redux/actions/message.action";
import "./SnackBarMessage.scss";

export default function SnackbarGeneralMessage({ message, dispatch }: any) {
  const handleClose = () => {
    dispatch(messageActions.clearMessage());
  };

  return (
    <Snackbar
      open={message.open}
      autoHideDuration={message.autoHideDuration}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={() => handleClose()}
      sx={{ marginBottom: "100px", maxWidth: "470px" }}
      className={"snackbar"}
    >
      <Alert
        severity={"info"}
        // action={
        //   <>
        //     <Typography
        //       variant="body2"
        //       sx={{
        //         marginTop: "8px",
        //         marginLeft: "24px",
        //         textTransform: "uppercase",
        //       }}
        //     >
        //       ACCEPT
        //     </Typography>
        //     <IconButton onClick={() => handleClose()}>
        //       <CloseIcon />
        //     </IconButton>
        //   </>
        // }
        onClose={() => handleClose()}
      >
        <AlertTitle>{message.messageTitle}</AlertTitle>
        {message.messageSubtitle}
      </Alert>
    </Snackbar>
  );
}
