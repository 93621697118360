import "./Header.scss";

const Header = ({
  setShowReservation,
  headerString,
  stepper,
  setStepper,
  setOpenWarningDialog,
}: any) => {
  return (
    <div className="reservations__header">
      <div className="reservations__left">
        {stepper > 1 && stepper < 4 && (
          <img
            src="media/back.png"
            alt="images"
            width={17}
            height={17}
            onClick={() => {
              setStepper(stepper - 1);
            }}
          />
        )}
        <h3 className="reservations__title">{headerString}</h3>
      </div>

      <div
        className="reservations__image"
        onClick={() => {
          setShowReservation(false);
          setOpenWarningDialog(false);

          document.body.classList.remove("body--scroll-disable");
        }}
      >
        <img src="media/x.png" alt="images" width={12} height={12} />
      </div>
    </div>
  );
};

export default Header;
