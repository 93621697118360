import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { setHours, setMinutes, addMonths, format, addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "./Calendar.scss";
import GuestsCounter from "../GuestsCounter/GuestsCounter";
import Footer from "../Footer/Footer";
import axios from "axios";
import { BlockedDay } from "../../../types/blockedDayInterface";

const Calendar = ({
  setGuestReservation,
  guestReservation,
  setDateFlag,
  dateFlag,
  showReservation,
  setIsDisable,
  isDisable,
  setShowReservation,
  setStepper,
  stepper,
  setValueAdults,
  setValueResidents,
  setValueChildren,
  valueAdults,
  valueChildren,
  valueResidents,
}: any): JSX.Element => {
  const [excludeDates, setExcludeDates] = useState<Date[]>([]);
  const [excludeTimes, setExcludeTimes] = useState<Date[]>([]);
  const [totalAdults, setTotalAdults] = useState(0);
  const [totalChildren, setTotalChildren] = useState(0);
  const [totalResidents, setTotalResidents] = useState(0);
  const [maxPeopleHour, setMaxPeopleHour] = useState(0);
  const [blockedDays, setBlockedDays] = useState<BlockedDay[]>([]);
  const datePlusOne = new Date(new Date().setDate(new Date().getDate() + 1));
  const [startDate, setStartDate] = useState<Date>(
    excludeDates.some(
      (excludeDate) =>
        format(excludeDate, "yyyy-MM-dd") ===
          format(new Date(), "yyyy-MM-dd") ||
        format(excludeDate, "yyyy-MM-dd") ===
          format(addDays(new Date(), 1), "yyyy-MM-dd")
    )
      ? addDays(excludeDates[excludeDates.length - 1], 1)
      : new Date() > setHours(setMinutes(new Date(), 0), 13)
      ? datePlusOne
      : new Date()
  );
  const includedDates = [
    setHours(setMinutes(new Date(), 0), 8),
    setHours(setMinutes(new Date(), 30), 9),
    setHours(setMinutes(new Date(), 0), 11),
    setHours(setMinutes(new Date(), 30), 12),
    setHours(setMinutes(new Date(), 0), 14),
    setHours(setMinutes(new Date(), 30), 15),
    setHours(setMinutes(new Date(), 0), 17),
  ];
  const includeHoursArray = includedDates.map((date) =>
    date.toString().split(" ", 5)[4].split(":", 2).join(":")
  );

  useEffect(() => {
    if (
      (valueAdults > 0 || valueChildren > 0 || valueResidents > 0) &&
      includeHoursArray.includes(
        startDate.toString().split(" ", 5)[4].split(":", 2).join(":")
      )
    ) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [
    includeHoursArray,
    setIsDisable,
    startDate,
    valueAdults,
    valueChildren,
    valueResidents,
  ]);

  useEffect(() => {
    getBlockedDaysMonthly();
  }, []);

  useEffect(() => {
    getBlockedTimes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockedDays]);

  const getBlockedTimes = (date: Date = startDate) => {
    setExcludeTimes(() => {
      const excludeTimes: Date[] = [];
      const matchedDate = blockedDays.filter(
        (blockedDay) =>
          blockedDay.dates
            .map((dateBlocked) => new Date(dateBlocked).getDate())
            .indexOf(date.getDate()) > -1
      );
      matchedDate.forEach((matched) =>
        matched.hours.forEach((hour) => {
          const [hours, minutes] = hour.split(":");
          excludeTimes.push(
            new Date(new Date(date.setHours(+hours)).setMinutes(+minutes))
          );
        })
      );
      return excludeTimes;
    });
  };

  const getBlockedDaysMonthly = (date: Date | null = null) => {
    axios
      .get(`${process.env.REACT_APP_RAILWAY}/days/blocked-monthly`, {
        params: {
          month: date?.getMonth(),
          year: date?.getFullYear(),
        },
      })
      .then(({ data }) => {
        setBlockedDays(data);
        (data as BlockedDay[]).forEach((blockedDay) => {
          if (blockedDay.hours.length === 7) {
            setExcludeDates((prevExcludeDates) =>
              prevExcludeDates.concat(
                ...blockedDay.dates.map((date) => new Date(date))
              )
            );
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setGuestReservation({
      ...guestReservation,
      adults: valueAdults,
      children: valueChildren,
      residents: valueResidents,
      date: startDate,
    });
    setStepper(stepper + 1);
    setDateFlag(true);
  };

  // eslint-disable-next-line no-extend-native
  const addHours = (h: number) => {
    const copiedDate = new Date();
    copiedDate.setHours(copiedDate.getHours() + h);
    return copiedDate;
  };

  return (
    <div className="calendar">
      <form className="calendar__form">
        <DatePicker
          selected={startDate}
          onMonthChange={getBlockedDaysMonthly}
          onChange={(date: Date) => {
            if (date.getDate() !== startDate.getDate()) {
              getBlockedTimes(date);
            }
            setValueAdults(0);
            setValueChildren(0);
            setValueResidents(0);
            setMaxPeopleHour(0);
            setStartDate(date);
          }}
          showTimeSelect
          minDate={
            excludeDates.some((excludeDate) => {
              return (
                format(excludeDate, "yyyy-MM-dd") ===
                  format(new Date(), "yyyy-MM-dd") ||
                format(excludeDate, "yyyy-MM-dd") ===
                  format(addDays(new Date(), 1), "yyyy-MM-dd")
              );
            }) &&
            excludeDates[excludeDates.length - 1].getMonth() ===
              new Date().getMonth()
              ? addDays(excludeDates[excludeDates.length - 1], 1)
              : new Date() > setHours(setMinutes(new Date(), 0), 13)
              ? datePlusOne
              : new Date()
          }
          maxDate={addMonths(new Date(), 5)}
          minTime={
            startDate.toDateString() === new Date().toDateString() &&
            new Date() > setHours(setMinutes(new Date(), 0), 17)
              ? setHours(setMinutes(new Date(), 0), 17)
              : startDate.toDateString() !== new Date().toDateString()
              ? setHours(setMinutes(new Date(), 0), 8)
              : addHours(4)
          }
          maxTime={setHours(setMinutes(new Date(), 0), 17)}
          includeTimes={includedDates}
          excludeTimes={excludeTimes}
          placeholderText="Choose a date"
          dateFormat="Pp"
          excludeDates={excludeDates}
          className="calendar__day-input"
          timeCaption="Time"
          timeFormat="p"
          inline
        />

        <div className="calendar__places-container">
          <p className="calendar__places">
            <span></span>
          </p>
        </div>

        <GuestsCounter
          totalAdults={totalAdults}
          setTotalAdults={setTotalAdults}
          totalChildren={totalChildren}
          setTotalChildren={setTotalChildren}
          totalResidents={totalResidents}
          setTotalResidents={setTotalResidents}
          setIsDisable={setIsDisable}
          isDisable={isDisable}
          maxPeopleHour={maxPeopleHour}
          valueAdults={valueAdults}
          setValueAdults={setValueAdults}
          valueChildren={valueChildren}
          setValueChildren={setValueChildren}
          valueResidents={valueResidents}
          setValueResidents={setValueResidents}
        />
        <Footer
          isDisable={isDisable}
          onSubmit={onSubmit}
          showReservation={showReservation}
          buttonName={"NEXT"}
          setStepper={setStepper}
          stepper={stepper}
        />
      </form>
    </div>
  );
};

export default Calendar;
