import { IActionTypes } from "../../types/actionsInterface";

const actionTypes: IActionTypes = {
  clearReservation: "CLEAR_RESERVATION",
  createReservation: "CREATE_RESERVATION",
  deleteReservation: "DELETE_RESERVATION",
  loadAllReservations: "LOAD_ALL_RESERVATIONS",
  loadCurrentReservation: "LOAD_CURRENT_RESERVATION",
};

export default actionTypes;
