import { IReservation } from "../../types/reservationInterface";
import actionTypes from "../actions/actionTypes";

const currentReservationReducer = (
  reservation: IReservation = {},
  action: any
) => {
  let newReservation;
  switch (action.type) {
    case actionTypes.loadCurrentReservation:
      newReservation = action.reservation;
      break;
    case actionTypes.clearReservation:
      newReservation = {};
      break;
    default:
      newReservation = reservation;
  }
  return newReservation;
};

export default currentReservationReducer;
