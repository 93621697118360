import { useState } from "react";
import "./Footer.scss";

const Footer = (): JSX.Element => {
  const [showInstagram, setShowInstagram] = useState(false);
  const [showFacebook, setShowFacebook] = useState(false);
  const [showTripAdvisor, setShowTripAdvisor] = useState(false);

  return (
    <footer className="sub-footer" id="contact">
      <h2 className="contact__title">CONTACT US</h2>
      <div className="sub-footer__container">
        <div className="sub-footer__container-column">
          <section className="contact">
            <h3 className="contact__sub-title">CONTACT</h3>
            <div className="contact__text">
              <p>
                Information:{" "}
                <span>
                  EMAIL |{" "}
                  <a href="mailto:info@maalumzanzibar.com">
                    info@maalumzanzibar.com
                  </a>{" "}
                </span>
              </p>
            </div>
          </section>
          <section className="location">
            <h3 className="location__title">Our Location</h3>
            <p className="location__direction">Paje</p>
          </section>
        </div>
        <section className="map">
          <div className="map__container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15863.580284911572!2d39.5308595!3d-6.2775242!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xad0717d2fee3609e!2sMAALUM!5e0!3m2!1ses!2ses!4v1655978628957!5m2!1ses!2ses"
              width="600"
              height="450"
              frameBorder="0"
              title="cenote zanzibar"
            ></iframe>
          </div>
        </section>
      </div>
      <div className="social-media">
        <div
          className="social-media__instagram"
          onMouseOut={() => setShowInstagram(false)}
          onMouseOver={() => setShowInstagram(true)}
        >
          <a href="https://www.instagram.com/maalumzanzibar/?hl=es">
            <img
              src="media/instagram-white.png"
              width={30}
              height={30}
              alt="instagram icon"
              className={
                !showInstagram
                  ? "social-media__instagram-white"
                  : "social-media__instagram-white--hidden"
              }
            />
            <img
              src="media/instagram-black.png"
              alt="instagram icon"
              width={30}
              height={30}
              className={
                showInstagram
                  ? "social-media__instagram-black--display"
                  : "social-media__instagram-black"
              }
            />
          </a>
        </div>
        <div
          className="social-media__facebook"
          onMouseOut={() => setShowFacebook(false)}
          onMouseOver={() => setShowFacebook(true)}
        >
          <img
            src="media/facebook-white.png"
            alt="instagram icon"
            width={30}
            height={30}
            className={
              !showFacebook
                ? "social-media__facebook-white"
                : "social-media__facebook-white--hidden"
            }
          />
          <img
            src="media/facebook-black.png"
            alt="instagram icon"
            width={30}
            height={30}
            className={
              showFacebook
                ? "social-media__facebook-black--display"
                : "social-media__facebook-black"
            }
          />
        </div>
        <div
          className="social-media__trip-advisor"
          onMouseOut={() => setShowTripAdvisor(false)}
          onMouseOver={() => setShowTripAdvisor(true)}
        >
          <a href="https://www.tripadvisor.es/Attraction_Review-g616020-d23946364-Reviews-Maalum-Paje_Zanzibar_Island_Zanzibar_Archipelago.html">
            <img
              src="media/tripadvisor-white.png"
              alt="instagram icon"
              width={30}
              height={30}
              className={
                !showTripAdvisor
                  ? "social-media__trip-advisor-white"
                  : "social-media__trip-advisor-white--hidden"
              }
            />
            <img
              src="media/tripadvisor-black.png"
              alt="instagram icon"
              width={30}
              height={30}
              className={
                showTripAdvisor
                  ? "social-media__trip-advisor-black--display"
                  : "social-media__trip-advisor-black"
              }
            />
          </a>
        </div>
      </div>
      {/* <div className="properties">
        <h3 className="properties__title">A CCJ HOSPITALITY PROPERTY</h3>
        <h3 className="properties__accommodations">OUR ACCOMMODATIONS</h3>
        <p>
          KAMANDA HOUSE, PAJE, ZANZIBAR -
          <a href="https://www.kamandahouse.com/">Stay with us</a>
        </p>
      </div> */}
    </footer>
  );
};

export default Footer;
