import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home/Home";
import { useDispatch, useSelector } from "react-redux";
import SnackbarGeneralMessage from "./components/SnackBarMessage/SnackBarMessage";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";

function App() {
  const dispatch = useDispatch();
  const message = useSelector(({ message }: any) => message);

  return (
    <div className="App">
      <SnackbarGeneralMessage message={message} dispatch={dispatch} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
