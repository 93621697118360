import { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Information from "../../components/Information/Information";
import useReservations from "../../hooks/useReservations";
import Reservations from "../Reservations/Reservations";

import "./Home.scss";
const Home = (): JSX.Element => {
  const { showReservation, setShowReservation } = useReservations();
  const [stepper, setStepper] = useState(1);
  return (
    <section className="home">
      <div className={showReservation ? "home__main--noscroll" : ""}>
        <Header
          show={true}
          setShowReservation={setShowReservation}
          setStepper={setStepper}
        />
        <Information
          showReservation={showReservation}
          setShowReservation={setShowReservation}
        />
        <Footer />
      </div>
      <Reservations
        showReservation={showReservation}
        setShowReservation={setShowReservation}
        setStepper={setStepper}
        stepper={stepper}
      />
    </section>
  );
};

export default Home;
