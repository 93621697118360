import { combineReducers } from "redux";
import reservationReducer from "./reservationReducer";
import currentReservationReducer from "./currentReservationReducer";
import { messageReducer } from "./message.reducer";

const rootReducer = combineReducers({
  reservations: reservationReducer,
  reservation: currentReservationReducer,
  message: messageReducer,
});

export default rootReducer;
