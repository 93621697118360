import { AlertColor } from "@mui/material";
import { messageTypes } from "../actions/message.types";

interface Payload {
  messageTitle: string;
  messageSubtitle: string;
  severity: AlertColor | "";
  autoHideDuration: number;
}
interface ActionType {
  type: string;
  payload: Payload;
  error: boolean;
}

const initialState: any = {
  messageTitle: "",
  messageSubtitle: "",
  autoHideDuration: 4000,
  severity: "",
  open: false,
};

export const messageReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case messageTypes.SET_MESSAGE:
      return {
        messageTitle: action.payload.messageTitle,
        messageSubtitle: action.payload.messageSubtitle,
        severity: action.payload.severity,
        autoHideDuration: action.payload.autoHideDuration,
        open: true,
      };
    case messageTypes.CLEAR_MESSAGE:
      return initialState;
    default:
      return state;
  }
};
