import Navbar from "../Navbar/Navbar";
import { NavLink } from "react-router-dom";
import "./Header.scss";
import { useState } from "react";

const Header = ({
  show,
  setShowReservation,
  setStepper,
  isTermsAndConditions = false,
}: any): JSX.Element => {
  const [scrollFlag, setScrollFlag] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 0) {
      setScrollFlag(true);
    } else {
      setScrollFlag(false);
    }
  });

  return (
    <header className={`header ${isTermsAndConditions && "header--half"}`}>
      <div
        className={
          !scrollFlag
            ? "header__container"
            : "header__container header__container--sticky"
        }
      >
        <div className="header__logo">
          <NavLink to="/">
            <img
              src="media/just-maalum.png"
              alt="logo maalum cenote"
              className="header__picture"
              width={157}
              height={38}
            />
          </NavLink>
        </div>
        <Navbar
          scrollFlag={scrollFlag}
          setShowReservation={setShowReservation}
          setStepper={setStepper}
        />
      </div>
      <h2
        className={`header__text ${
          isTermsAndConditions && "header__text--half"
        }`}
      >
        {"WELCOME TO PARADISE"}
      </h2>
    </header>
  );
};

export default Header;
