import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createReservationThunk,
  loadAllReservationsThunk,
  loadCurrentReservationThunk,
  deleteReservationThunk,
} from "../redux/thunks/reservationsThunk";
import { clearReservationAction } from "../redux/actions/actionsCreator";
import { IReservation } from "../types/reservationInterface";

const useReservations = () => {
  const dispatch = useDispatch();
  const reservations = useSelector(({ reservations }: any) => reservations);
  const reservation = useSelector(({ reservation }: any) => reservation);
  const [showReservation, setShowReservation] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const createReservation = (reservation: IReservation) =>
    dispatch(createReservationThunk(reservation));

  const loadAllReservations = useCallback(() => {
    dispatch(loadAllReservationsThunk());
  }, [dispatch]);

  const loadCurrentReservation = (id: string) =>
    dispatch(loadCurrentReservationThunk(id));

  const clearReservation = () => dispatch(clearReservationAction());

  const deleteReservation = (id: string) =>
    dispatch(deleteReservationThunk(id));

  return {
    reservations,
    reservation,
    clearReservation,
    createReservation,
    loadAllReservations,
    loadCurrentReservation,
    deleteReservation,
    showReservation,
    showHeader,
    setShowReservation,
    setShowHeader,
  };
};

export default useReservations;
