import "./Footer.scss";

const Footer = ({
  isDisable,
  onSubmit,
  showReservation,
  buttonName,
  stepper,
  guestReservation,
}: any) => {
  return (
    <div
      className={
        !showReservation
          ? "calendar__footer"
          : stepper === 1
          ? "calendar__footer calendar__footer--active calendar__footer--margin"
          : "calendar__footer calendar__footer--active"
      }
    >
      {buttonName === "SEND" && !isDisable ? (
        <a
          href={`https://wa.me/255772628924?text=Hi%20Maalum,%20I'm%20${
            guestReservation.name
          } ${
            guestReservation.surname
          },%20I%20would%20like%20to%20make%20a%20reservation%20for%20the%20${
            guestReservation?.date?.toString().split(" ")[2]
          }%20of%20${
            guestReservation?.date
              ?.toLocaleString("en", {
                dateStyle: "long",
              })
              .split(" ")[0]
          },%20at%20${guestReservation.date
            ?.toString()
            .split(" ", 5)[4]
            .split(":", 2)
            .join(":")}%20for%20${guestReservation?.adults}%20${
            +guestReservation?.adults === 1 ? "adult" : "adults"
          },%20${guestReservation?.children}%20${
            +guestReservation?.children === 1 ? "child" : "children"
          }%20and%20${guestReservation?.residents}%20${
            +guestReservation?.residents === 1 ? "resident" : "residents"
          }.%20Thank%20you%20:)`}
          className="calendar__whatsapp"
          onClick={() => {
            stepper = 1;
          }}
        >
          {buttonName}
          <i className="fa fa-whatsapp " aria-hidden="true"></i>
        </a>
      ) : (
        <button
          type="submit"
          className="calendar__button"
          disabled={isDisable}
          onClick={onSubmit}
        >
          {buttonName}
        </button>
      )}
    </div>
  );
};

export default Footer;
