import { useState } from "react";
import Header from "../../components/Header/Header";
import useReservations from "../../hooks/useReservations";
import Reservations from "../Reservations/Reservations";
import "./TermsAndConditions.scss";

const TermsAndConditions = () => {
  const { showReservation, setShowReservation } = useReservations();
  const [stepper, setStepper] = useState(1);
  return (
    <section className="terms-and-conditions">
      <div>
        <Header
          show={true}
          setShowReservation={setShowReservation}
          setStepper={setStepper}
          isTermsAndConditions={true}
        />
        <Reservations
          showReservation={showReservation}
          setShowReservation={setShowReservation}
          setStepper={setStepper}
          stepper={stepper}
        />
        <article className="terms-and-conditions__block">
          <h2 className="terms-and-conditions__title">TERMS AND</h2>
          <h2 className="terms-and-conditions__title terms-and-conditions__title--green">
            CONDITIONS
          </h2>
          <p>
            It is important to note that our cancellation policy does not
            include refunds.
          </p>
          <p>
            However, we understand that unforeseen circumstances may arise, and
            we are willing to provide alternative options. If you need to make
            changes to the dates of your reservation, we are happy to assist you
            in finding a suitable solution when informed 24 hours before your
            booking time.
          </p>{" "}
          <p>
            Changes are subject to availability and bookings can only be
            modified once.
          </p>
          <p>
            Please feel free to reach out to our customer service team to
            discuss your needs and find a solution that works best for you. We
            are here to assist you and make sure you enjoy your stay with us.
          </p>
        </article>
      </div>
    </section>
  );
};

export default TermsAndConditions;
