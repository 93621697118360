import { createTheme, Theme } from "@mui/material/styles";

export const defaultTheme: Theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "Montserrat-Regular";
          src: url("../../public/fonts/Montserrat-Regular.ttf");
        }
      `,
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&:after": {
            borderBottom: "1px solid black",
            color: "black",
          },
          fontFamily: "Montserrat-Regular",
          fontSize: "0.85rem",
          letterSpacing: "1px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Montserrat-Regular",
          fontSize: "0.85rem",
          color: "gray",
          top: "-2px",
          letterSpacing: "1px",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: "15px",
          width: "100%",
        },
      },
    },
  },
});
