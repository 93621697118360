import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import "./GuestInfo.scss";
import moment from "moment";
const GuestInfo = ({
  guestReservation,
  setGuestReservation,
  showReservation,
  setStepper,
  stepper,
}: any) => {
  const [isDisable, setIsDisable] = useState(true);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGuestReservation({
      ...guestReservation,
      [event.target.id]: event.target.value,
    });
  };

  useEffect(() => {
    if (guestReservation.name.length && guestReservation.surname) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [guestReservation.name, guestReservation.surname]);

  const priceCalculation = () => {
    return (
      guestReservation.adults * 20 +
      guestReservation.children * 10 +
      guestReservation.residents * 15
    );
  };
  const onSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    setGuestReservation({
      ...guestReservation,
      name: guestReservation.name + ` ${guestReservation.surname}`,
    });
    delete guestReservation.surname;
    event.preventDefault();
    // setStepper(stepper + 1);
  };

  return (
    <>
      <div className="guest-info">
        <div className="guest-info__inputs">
          <h2>Personal information</h2>
          <div className="guest-info__text-fields">
            <TextField
              label="Name"
              id="name"
              type="text"
              onChange={onChange}
              value={guestReservation.name}
              required
            />
            <TextField
              label="Surname"
              id="surname"
              type="text"
              //variant="standard"
              onChange={onChange}
              value={guestReservation.surname}
              required
            />
          </div>
        </div>
        <div className="guest-info__reservation">
          <h2>Request summary</h2>
          <div className="guest-info__info-container">
            <h3>
              <span>Date:</span>{" "}
              {moment(guestReservation.date).format("DD/MM/YYYY")}
            </h3>
            <h3>
              <span>Time</span>{" "}
              {guestReservation.date
                .toString()
                .split(" ", 5)[4]
                .split(":", 2)
                .join(":")}
            </h3>
            {guestReservation.adults > 0 && (
              <h3>
                <span>
                  {`${guestReservation.adults === 1 ? "Adult" : "Adults"}:`}
                </span>{" "}
                {guestReservation.adults}
              </h3>
            )}
            {guestReservation.children > 0 && (
              <h3>
                <span>
                  {`${guestReservation.children === 1 ? "Child" : "Children"}:`}
                </span>{" "}
                {guestReservation.children}
              </h3>
            )}
            {guestReservation.residents > 0 && (
              <h3>
                <span>
                  {`${
                    guestReservation.residents === 1 ? "Resident" : "Residents"
                  }
                  :`}
                </span>{" "}
                {guestReservation.residents}
              </h3>
            )}
            <div className="guest-info__total">
              <h3>
                <span>Total:</span> {`${priceCalculation()}$`}
              </h3>
            </div>
          </div>
          <p className="guest-info__payment">
            For drone use a previous request on your booking is needed
          </p>
          <p className="guest-info__payment guest-info__payment--last">
            To proceed with your booking, you will be redirected to whatsapp
          </p>
        </div>
      </div>

      <Footer
        isDisable={isDisable}
        onSubmit={onSubmit}
        showReservation={showReservation}
        buttonName={"SEND"}
        guestReservation={guestReservation}
      />
    </>
  );
};

export default GuestInfo;
