import actionTypes from "./actionTypes";
import { IReservation } from "../../types/reservationInterface";

export const createReservationAction = (reservation: IReservation) => ({
  type: actionTypes.createReservation,
  reservation,
});

export const clearReservationAction = () => ({
  type: actionTypes.clearReservation,
});

export const loadAllReservationsActions = (
  reservations: Array<IReservation>
) => ({
  type: actionTypes.loadAllReservations,
  reservations,
});

export const loadCurrentReservationAction = (reservation: IReservation) => ({
  type: actionTypes.loadCurrentReservation,
  reservation,
});

export const deleteReservationAction = (id: string) => ({
  type: actionTypes.deleteReservation,
  id,
});
