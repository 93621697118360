import { useState } from "react";
import "./Navbar.scss";

const Navbar = ({
  scrollFlag,
  setShowReservation,
  setStepper,
}: any): JSX.Element => {
  const [active, setActive] = useState(false);
  const onClickMenu = () => {
    setActive(!active);
  };
  const onClickBook = () => {
    setActive(!active);
    setShowReservation(true);
    document.body.classList.add("body--scroll-disable");
  };

  return (
    <>
      <div
        className={active ? "menu menu--active" : "menu"}
        onClick={onClickMenu}
      >
        <span className="menu__line"></span>
        <span className="menu__line"></span>
        <span className="menu__line"></span>
      </div>
      <ul className={active ? "navigation navigation--active" : "navigation"}>
        <li
          className={
            !scrollFlag
              ? "navigation__element"
              : "navigation__element navigation__element--sticky"
          }
          onClick={onClickMenu}
        >
          {/* <a
            href="#restaurant"
            className={
              !scrollFlag
                ? "navigation__element"
                : "navigation__element navigation__element--sticky"
            }
          >
            RESTAURANT
          </a> */}
        </li>
        <li
          className={
            !scrollFlag
              ? "navigation__element"
              : "navigation__element navigation__element--sticky"
          }
          onClick={onClickMenu}
        >
          <a
            href="/#contact"
            className={
              !scrollFlag
                ? "navigation__element"
                : "navigation__element navigation__element--sticky"
            }
          >
            CONTACT US
          </a>
        </li>
        <li
          className={
            !scrollFlag
              ? "navigation__element"
              : "navigation__element navigation__element--sticky-book"
          }
          onClick={onClickBook}
        >
          BOOK NOW
        </li>
      </ul>
    </>
  );
};

export default Navbar;
