import { AlertColor } from "@mui/material";
import { messageTypes } from "./message.types";

const setMessage = (
  messageTitle: string,
  messageSubtitle: string,
  severity: AlertColor,
  autoHideDuration: number
) => {
  return {
    type: messageTypes.SET_MESSAGE,
    payload: { messageTitle, messageSubtitle, severity, autoHideDuration },
  };
};
const clearMessage = () => {
  return {
    type: messageTypes.CLEAR_MESSAGE,
  };
};

export const messageActions = {
  setMessage,
  clearMessage,
};
