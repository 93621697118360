// import { Restaurant } from "@mui/icons-material";
import "./Information.scss";
import Pledge from "./Pledge/Pledge";
import Restaurant from "./Restaurant/Restaurant";

const Information = ({
  showReservation,
  setShowReservation,
}: any): JSX.Element => {
  return (
    <div>
      <section className="information">
        <h2 className="information__title">TAKE A BREATH</h2>
        <h2 className="information__title information__title--green">
          LOVE NATURE
        </h2>
        <p className="information__text">
          Welcome to Maalum, a unique natural swimming pool in Paje, Zanzibar. A
          special place to reconnect with nature, explore the underground life,
          relax and enjoy the peace.
        </p>
        <p>The word Maalum means Special in Swahili language.</p>
      </section>
      <section className="images-container">
        <div className="images-container__container">
          <img src="media/pic4-912.jpeg" alt="cenote zanzibar" />
        </div>
        <div className="images-container__container">
          <img src="media/pic3.jpeg" alt="cenote zanzibar" />
        </div>
        <div className="images-container__container">
          <img src="media/pic2.jpg" alt="cenote zanzibar" />
        </div>
        <div className="images-container__container images-container__container--responsive">
          <img src="media/pic1.jpg" alt="cenote zanzibar" />
        </div>
      </section>
      <section className="entrances">
        <h2 className="entrances__title">BOOK NOW</h2>
        <div className="entrances__flex">
          <div className="entrances__information">
            <h3 className="entrances__subtitle">NATURAL SWIMMING POOL</h3>
            {/* <p className="entrances__text">
              In order for you to enjoy the magical experience to the fullest
              there is a limit of people at the same time in the cave area.
            </p> */}
            <p className="entrances__text">
              Ensure your spot by booking in advance
            </p>
            <p className="entrances__text entrances__text--modification">
              The duration of the slot is 90 min. inside the cave area
            </p>
          </div>
          <ul className="entrances__icons">
            <li className="entrances__icons-element">
              <img
                src="https://static1.squarespace.com/static/5edbafe6caad7c68afd91215/t/609efe990d9395780c51aa48/1621032601945/private_outdoor_seating-01.svg"
                alt="beach cenote zanzibar"
                width={22}
                height={22}
              />
              <p>Chill and relax</p>
            </li>
            <li className="entrances__icons-element">
              <img
                src="https://static1.squarespace.com/static/5edbafe6caad7c68afd91215/t/609efeb4ce15a13eb81a4202/1621032628107/breakfast_included-01.svg"
                alt="beach cenote zanzibar"
                width={22}
                height={22}
              />
              <p>Restaurant Service</p>
            </li>
            <li className="entrances__icons-element">
              <img
                src="https://static1.squarespace.com/static/5edbafe6caad7c68afd91215/t/609efed38254c307c1f01610/1621032659724/all_natural_soaps-01-01.svg"
                alt="beach cenote zanzibar"
                width={22}
                height={22}
              />
              <p>All natural soaps</p>
            </li>
            <li className="entrances__icons-element">
              <img
                src="https://static1.squarespace.com/static/5edbafe6caad7c68afd91215/t/609eff193eba882097c37649/1621032729616/parking_included-01-white.svg"
                alt="beach cenote zanzibar"
                width={22}
                height={22}
              />
              <p>Parking</p>
            </li>
            <li className="entrances__icons-element">
              <img
                src="https://static1.squarespace.com/static/5edbafe6caad7c68afd91215/t/609eff2be93ae07ca7dfdacc/1621032747753/safe_included-01-white.svg"
                alt="beach cenote zanzibar"
                width={22}
                height={22}
              />
              <p>Changing Rooms and Lockers</p>
            </li>
            <li className="entrances__icons-element entrances__icons-element--font-awesome">
              <img
                src="media/shower.png"
                alt="beach cenote zanzibar"
                width={21}
                height={21}
              />
              <p>Shower</p>
            </li>
            <li className="entrances__icons-element entrances__icons-element--font-awesome">
              <img
                src="media/towel.png"
                alt="beach cenote zanzibar"
                width={21}
                height={21}
              />
              <p>Towels</p>
            </li>
            <li className="entrances__icons-element entrances__icons-element--font-awesome">
              <img
                src="media/googles.png"
                alt="beach cenote zanzibar"
                width={22}
                height={22}
              />
              <p>Diving masks</p>
            </li>
          </ul>
        </div>

        <div className="entrances__book">
          <p
            onClick={() => {
              setShowReservation(true);
              document.body.classList.add("body--scroll-disable");
            }}
            id="restaurant"
          >
            BOOK NOW
          </p>{" "}
        </div>
      </section>
      {/* <Restaurant />
      <Pledge /> */}
      <section className="instagram">
        <h2 className="instagram__title">FOLLOW US ON INSTAGRAM</h2>
        <div className="instagram__images">
          <div className="instagram__image">
            <a
              href="https://www.instagram.com/p/CXy4MRvsRaA/?hl=es"
              target="_blank"
              rel="noreferrer"
            >
              <img src="media/instagram3.jpg" alt="instagram zanzibar" />
            </a>
          </div>
          <div className="instagram__image">
            <a
              href="https://www.instagram.com/p/CXigyu5M0mL/?hl=es"
              target="_blank"
              rel="noreferrer"
            >
              <img src="media/instagram2.jpg" alt="instagram zanzibar" />
            </a>
          </div>
          <div className="instagram__image">
            <a
              href="https://www.instagram.com/p/CXjMQouM8Ux/?hl=es"
              target="_blank"
              rel="noreferrer"
            >
              <img src="media/instagram4.jpg" alt="instagram zanzibar" />
            </a>
          </div>
          <div className="instagram__image">
            <a
              href="https://www.instagram.com/p/CXkjRdLozkJ/?hl=es"
              target="_blank"
              rel="noreferrer"
            >
              <img src="media/instagram1.jpg" alt="instagram zanzibar" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Information;
