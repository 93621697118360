import axios from "axios";
import { Dispatch } from "react";
import {
  ICreateReservation,
  ILoadAllReservations,
  //ILoadCurrentReservation,
  IDeleteReservation,
} from "../../types/actionsInterface";
import { IReservation } from "../../types/reservationInterface";
import {
  createReservationAction,
  deleteReservationAction,
  loadAllReservationsActions,
  loadCurrentReservationAction,
} from "../actions/actionsCreator";
import { messageActions } from "../actions/message.action";

const urlServer: string | undefined = process.env.REACT_APP_URL;

export const createReservationThunk = (reservation: IReservation) => {
  return async (dispatch: Dispatch<ICreateReservation>) => {
    try {
      const response = await axios.post(
        `${urlServer}reservations/create`,
        reservation
      );
      if (response.status === 201) {
        dispatch(createReservationAction(response.data));
      }
    } catch (error) {
      return error;
    }
  };
};

export const loadAllReservationsThunk = () => {
  return async (dispatch: Dispatch<ILoadAllReservations>) => {
    try {
      const response = await axios.get(`${urlServer}reservations/getAll`);
      if (response.status === 200) {
        dispatch(loadAllReservationsActions(response.data));
      }
    } catch (error) {
      return error;
    }
  };
};

export const loadCurrentReservationThunk = (id: string) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${urlServer}reservations/get/${id}`);
      if (response.status === 200) {
        dispatch(loadCurrentReservationAction(response.data));
      }
    } catch (error) {
      dispatch(
        messageActions.setMessage(
          "Failed to find the reservation",
          "It seems to have been an error, please check if the id provided was correct",
          "error",
          5000
        )
      );
    }
  };
};

export const deleteReservationThunk = (id: string) => {
  return async (dispatch: Dispatch<IDeleteReservation>) => {
    try {
      const response = await axios.delete(
        `${urlServer}reservations/delete/${id}`
      );
      if (response.status === 200) {
        dispatch(deleteReservationAction(response.data));
      }
    } catch (error) {
      return error;
    }
  };
};
